import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/layout.js";
import { Link } from "gatsby";
import SEO from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="FAQ" mdxType="SEO" />
    <h2>{`Вопросы, которые стесняются задавать, а зря!`}</h2>
    <h4>{`Какие дополнительные инструменты может использовать разработчик для проверки кода перед прохождением код-ревью?`}</h4>
    <p>{`Появление линтеров (самый популярный - `}<inlineCode parentName="p">{`eslint`}</inlineCode>{`) позволило разработчикам сосредоточить ревью на
результате работы кода, а не на его форме. Многие компании поддерживают свой eslint конфиг,
чаще всего являющийся форком `}<inlineCode parentName="p">{`eslint-config-airbnb`}</inlineCode>{`. Для автоматического форматирования
наиболее удобно использовать `}<inlineCode parentName="p">{`prettier`}</inlineCode>{` прямо в редакторе кода при сохранении файла. Для большей
надежности можно автоматизировать запуск форматтера перед любым коммитом. Для этого нужно установить
npm библиотеки `}<inlineCode parentName="p">{`husky`}</inlineCode>{` и `}<inlineCode parentName="p">{`lint-staged`}</inlineCode>{` и указать запуск желаемых скриптов в `}<inlineCode parentName="p">{`package.json`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "husky": {
    "hooks": {
      "pre-commit": "lint-staged"
    }
  },
  "lint-staged": {
    "**/*.js": [
      "prettier --write",
      "git add"
    ]
  }
}
`}</code></pre>
    <h4>{`Бойлерплейт - что это?`}</h4>
    <p>{`Бойлерплейт - это повторяющийся код, приводящий к определенному результату и ощущаемый как избыточный для него.
Чаще всего бойлерплейт является результатом компромисса между создателями языков, библиотек и их пользователями.`}</p>
    <p>{`В идеальном мире библиотека будет решать поставленную задачу с минимальным количеством символов,
но в реальном разработчику библиотеки часто приходится балансировать между удобством использования
библиотеки, количеством функционала и ее гибкостью. Многофункциональность часто отражается на количестве кода,
который пользователь должен написать для решения задачи с помощью этой библиотеки.`}</p>
    <h4>{`Что отличает мидл специалиста от джуна?`}</h4>
    <p>{`Определение уровней джун и мидл у каждой компании свое. Разработчик может считаться сеньором в одной компании,
когда в другой он будет джуном. Следующие три вещи помогут отличить джуна от мидла:`}</p>
    <ul>
      <li parentName="ul">{`Количество продуктовых задач, которые разработчик может решить без консультаций с другими разработчиками`}</li>
      <li parentName="ul">{`Скорость дебага`}</li>
      <li parentName="ul">{`Кругозор, позволяющий быстрее устанавливать корень проблемы и глубже её решать`}</li>
    </ul>
    <p>{`Джун зачастую пытается просто заставить код работать. Поверхностно понимает как работает JS изнутри,
долго разбирается с новыми библиотеками, избегает глубокого изучения документации библиотек.
Часто он не знает устройство деплоя и стейджинга в компании, что нередко приводит к продолжительному дебагу.
Джун медленно пишет тесты и чаще всего тестирует не то, поэтому не понимает, как они экономят время,
не ориентируется в паттернах проектирования, алгоритмах, не знает структуры данных.`}</p>
    <p>{`Нередко джун маскирует своё непонимание того, как решить задачу, берётся за задачу без лишних уточнений
и вопросы по ней возникают по ходу выполнения, что нередко приводит к рефакторингу решения.
Чаще всего к началу выполнения задачи он не представляет итогового решения и выводит его во время разработки.`}</p>
    <p>{`Мидл во время приема задачи в работу уже мысленно ее решает, видит краевые кейсы,
которые старается сразу обсудить с ПМом. Он может решить большинство продуктовых задач без консультаций
с другими разработчиками, когда джун только малую часть. Мидл уверенно ориентируется в стеке компании,
окружениях прода и стейджинга, понимает, что происходит на бэкенде. Он понимает как работает JS
и используемый фреймворк, знаком с паттернами проектирования, может решать алгоритмические задачи,
умеет планировать и выполнять рефакторинг значимых частей приложения.`}</p>
    <Link to="/" mdxType="Link">Вернуться на главную</Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      